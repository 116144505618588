
(function($) {

    let selectedCountries = [],
        country = $('.country-list li'),
        $selectedCountriesList = $('.selected-countries-list'),
        $clearButton = $('span.clear-all'),
        $noResults = $('.no-results');

    const App = (() => {
        const state = {
            selectedCountries: [],
            compareUrl: ''
        }

        const $filters = $('.category-filters')
        const $countryListItem = $('.country-list:not(.selected-countries-list) li')
        const $selectedCountryList = $('.selected-countries-list')
        const $clearAllButton = $('.clear-all')
        const $compareButton = $('.compare-btn')
        const $condenseButton = $('.condense-btn')
        const baseCompareUrl = $compareButton.attr('href')
        const $compareMsg = $('.compare-msg')
        const maxCountries = 3
        const selectedCountryCardHTML = ({ name, flagImgSrc }) => {
            return `<li class="country-card" data-country="${name}">
                        <span>
                            <div class="flag">
                                <img src="${flagImgSrc}" alt="${name} Flag" />
                            </div>
                            <span class="country-name">${name}</div>
                        </span>
                        <div class="remove-country">x</div>
                    </li>`
        }

        /**
         * Init the app
         */
        const init = () => {
            // Setup events
            setupEvents();

            // Update sticky CSS
            updateStickyCSS();
        }

        /**
         * Create and pass off events
         */
        const setupEvents = () => {
            // When a country card is clicked
            $('.country-list:not(.selected-countries-list) li').on('click', function() {
                beforeUpdateState();

                // Update state
                countryClicked(this);

                // Perform other actions once state is updated
                afterUpdateState();
            });

            window.onload = () => {
            
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const countries = urlParams.has('countries') ? urlParams.get('countries').split(',') : null;

                if(Array.isArray(countries)) {
                    // Add country cards to selected list
                    updateSelectedCountriesFromQueryStr(countries);

                    // Update the compare URL
                    buildCompareUrl();
                }   
            }

            // When the "remove country" button is clicked
            $(document).on('click', '.selected-countries-list li .remove-country', function() {

                // afterUpdateState(this, REMOVE_COUNTRY)
                beforeUpdateState(this);
                
                // Remove country from selected list state
                removeSelectedCountry($(this).closest('li'));

                // Perform other actions based on updated state
                afterUpdateState();
            });

            // When the "read more" button is clicked
            $('.country-list li a.read-more').on('click', function(e) {
                e.stopPropagation();
                readMoreButtonClicked(e);
            });

            // When the "Clear All" button is clicked
            $clearAllButton.on('click', function() {
                beforeUpdateState(this);

                // Remove all countries from selected list
                setSelectedCountries([]);

                // Perform other actions based on updated state
                afterUpdateState();
            });

            // When the filter arrow (condense) button is clicked
            $condenseButton.on('click', function() {
                $filters.toggleClass('condensed')
            });

            // When #filters sidebar is clicked
            $filters.on('click', function(e) {
                // Make clicking on #filters show the filters
                // If not clicking on #filters OR "Categories" text, return
                if(e.target !== this && !$(e.target).hasClass('categories-text-condensed')) return;

                // Else, if condensed, open it
                if($filters.hasClass('condensed'))  $filters.removeClass('condensed')
            })

            $(window).on('resize', function() {
                updateStickyCSS();
            })
        }

        const setSelectedCountries = countries => state.selectedCountries = countries

        /**
         * Runs before state is updated
         */
        const beforeUpdateState = el => {
            // If max countries is reached, show error message
            showIfCompareError();
            hideIfNoCompareError();
        }

        /**
         * Runs after state is updated
         */
        const afterUpdateState = () => {
            // If selected countries is less than max and error message is visible, hide it
            hideIfNoCompareError();

            // Update compare URL
            buildCompareUrl();

            // Render screen
            render();
        }

        const showIfCompareError = () => {
            // If max countries is reached, show error message
            if(state.selectedCountries.length >= maxCountries) {
                $compareMsg.css('visibility', 'visible')
            }

        }

        const hideIfNoCompareError = () => {
            if($compareMsg.is(':visible') && state.selectedCountries.length < maxCountries) $compareMsg.css('visibility', 'hidden')
        }

        const updateSelectedCountriesFromQueryStr = (countries) => {
            const countryElements = [];

            // Loop through countries, find corresponding element in country list, add to selectedCountries
            countries.forEach(countryName => {
                const countryEl = $('.country-list:not(.selected-countries-list) li').find('span.country-name:contains(' + countryName + ')').closest('li')

                console.log({countryEl});

                // Make sure it's found, if not return
                if(!countryEl.length) return;

                const country = {
                    name: getCountryName(countryEl),
                    flagImgSrc: getCountryFlagImgSrc(countryEl),
                };

                countryElements.push(country)
            })

            setSelectedCountries(countryElements);
            afterUpdateState();

            console.log({state: state.selectedCountries});
        }

        /**
         * Build compare URL
         */
        const buildCompareUrl = () => {
            const params = new URLSearchParams({
                countries: state.selectedCountries.map(c => c.name).join(',')
            })

            state.compareUrl = baseCompareUrl + '&' + params.toString();
        }

        /**
         * Gets the country name from the clicked country card element
         */
        const getCountryName = countryDiv => {
            return $(countryDiv).find('span.country-name').text().trim();
        }

        /**
         * Gets the flag image SRC from the clicked country card element
         */
        const getCountryFlagImgSrc = countryDiv => {
            return $(countryDiv).find('.flag img').attr('src');
        }

        /**
         * Add/remove country from selected list
         */
        const countryClicked = countryDiv => {
            let countryName = getCountryName(countryDiv),
                isSelected = state.selectedCountries.find(country => country.name === countryName)

            if(isSelected) {
                removeSelectedCountry(countryDiv);
            } else {
                addSelectedCountry(countryDiv)
            }
        }
        
        const readMoreButtonClicked = e => {
            e.stopPropagation();
        }

        /**
         * Adds country to selected list
         */
        const addSelectedCountry = countryDiv => {
            // Make sure they don't select more than the max # of countries (3 by default)
            if(state.selectedCountries.length >= maxCountries) return;

            let countryName = getCountryName(countryDiv),
                countryFlagImgSrc = getCountryFlagImgSrc(countryDiv)
                newCountry = {
                    name: countryName,
                    flagImgSrc: countryFlagImgSrc
                }

            setSelectedCountries([
                ...state.selectedCountries,
                newCountry
            ]);
        }

        /**
         * Removes country to selected list
         */
        const removeSelectedCountry = countryDiv => {
            let countryName = getCountryName(countryDiv),
                updatedSelectedCountries = state.selectedCountries.filter(country => country.name !== countryName);

            setSelectedCountries(updatedSelectedCountries);
        }

        /**
         * Render/update the UI
         */
        const render = () => {
            renderSelectedCountriesHTML();
            updateCountryListSelectionHTML();

            // Update compare button href attribute
            $compareButton.attr('href', state.compareUrl);
        }
        
        const renderSelectedCountriesHTML = () => {
            //console.log('render selected countries');
            let $selectedCountriesHTML = state.selectedCountries.map(country => {
                return selectedCountryCardHTML(country)
            })

            // console.log($selectedCountriesHTML)

            $selectedCountryList.html($selectedCountriesHTML.join(''));
        }

        const updateCountryListSelectionHTML = () => {
            $countryListItem.each(function() {
                let countryName = getCountryName(this),
                    isSelected = state.selectedCountries.find(country => country.name === countryName);

                if(isSelected) {
                    $(this).addClass('selected')
                } else {
                    $(this).removeClass('selected')
                }
            });
        }

        const updateStickyCSS = () => {
            const $sticky = $('.stick-to-top'),
                isLoggedIn = $('body').hasClass('logged-in'),
                headerHeight = $('.elementor-location-header').outerHeight(),
                adminBarHeight = isLoggedIn && $('#wpadminbar').length ? $('#wpadminbar').height() : 0,
                offsetTop = headerHeight + adminBarHeight, // 20 to give a little extra spacing below header
                $countryHeading = $('.country-heading'),
                countryHeadingHeight = $countryHeading.outerHeight(),
                $categoryTitle = $('.category-title')

            $sticky.each(function() {
                $(this).css('top', offsetTop + 'px')
                $filters.css('top', offsetTop + 'px')
                $countryHeading.css('top', offsetTop + 'px')
                $categoryTitle.css('top', offsetTop + countryHeadingHeight + 'px')
            });
        }

        return { init }
    })();
    
    App.init();

    /**
     * Handle filters
     */
    const $categoryFilters = $('.category-filters input[type="checkbox"]'),
        $toggleRow = $('.toggle-row'),
        $categoryTitle = $('.category-title'),
        $errorMessages = $('.error-messages');

    // Show sections based on which fields are selected
    $('input[type="checkbox"]:not([name="select-all"])').on('change', function() {
        // Show/hide selected category
        let id = $(this).attr('id'),
            checked = this.checked,
            $selectedToggleRow = $('.toggle-row[data-category="' + id + '"]'),
            checkedFilters = [];

        // Check if any other filters are checked
        $categoryFilters.each(function(filter) {
            console.log(this);
            if(this.checked) console.log('checked')
            if(this.checked) checkedFilters.push(filter)
        });

        console.log(checkedFilters.length)

        if(checked) {
            $selectedToggleRow.show();
            
            if(checkedFilters.length <= 1) $selectedToggleRow.addClass('opened')
        } else {
            $selectedToggleRow.hide();
        }

        handleErrorMessages();
    });

    // Select all checkboxes if "Select All" is checked
    $('input[name="select-all"]').change(function() {
        selectAllClicked(this);
        handleErrorMessages();
    });

    const handleErrorMessages = () => {
        // Loop through all checkboxes to see if any are selected
        let show = true;

        $categoryFilters.each(function() {
            if($(this).attr('name') == 'select-all') return;
            
            if(this.checked) {
                show = false;
            }
        });

        showErrorMessages(show);
    }

    const showErrorMessages = show => {
        if(show) {
            $errorMessages.show();
        } else {
            $errorMessages.hide();
        }
    }

    const selectAllClicked = el => {
        if(el.checked) {
            $categoryFilters.each(function() {
                $(this).prop('checked', true);
            });

            $toggleRow.each(function() {
                $(this).show();
            })
        } else {
            $categoryFilters.each(function() {
                $(this).prop('checked', false);
            });

            $toggleRow.each(function() {
                $(this).hide();
            });
        }
    }

    /**
     * Accordion/toggle functionality for country data
     */
    $categoryTitle.on('click', function() {
        const $row = $(this).parent();
        $row.toggleClass('opened');
    })
    
    /**
     * Filter countries on search
     */ 
    const filterCountries = (element) => {
        var value = $(element).val().toLowerCase();
    
        $(".country-list:not(.selected-countries-list) > li span").each(function() {
            if($(this).text().toLowerCase().search(value) > -1) {
                $(this).parent().show();
            } else {
                $(this).parent().hide();
            }
        });
    }

    $('input[name="filter-countries"]').keyup(function() {
        let searchStr = $(this).val().toLowerCase(),
            matches = 0;

        // If no countries found, show 'No results' message
        country.each(function() {
            if($(this).text().toLowerCase().search(searchStr) > -1) {
                matches++;
            }
        });

        if(!matches) {
            $noResults.show();
        } else {
            $noResults.hide();
        }

        // Filter list of countries
        filterCountries(this);
    })

})(jQuery);